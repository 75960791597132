/* Global Style */
*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-weight: 500;
    min-height: 100vh;
}

.landing-container {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    /*font-family: "Montserrat", sans-serif; Not working for some odd reasons*/
    background: transparent url('../img/background.png') 0 0 no-repeat padding-box;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    min-height: 100%;
}

.landing-container:before {
    content: '';
    background: #DD490ACC;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.landing-container.flex-direction-row {
    flex-direction: row;
}

div.landing-container > * {
    z-index: 1;
}

.text-centered {
    text-align: center;
}

.full-height {
    height: 100vh;
}

.flex-full-width {
    flex: 1 100%;
}

button {
    cursor: pointer;
}

/* END Global Style */

/* Header */

header {
    display: flex;
    justify-content: center;
}

.header-logo {
    width: auto;
    height: 141px;
    max-width: 100%;
    margin-top: 53px;
    opacity: 0.9;
}

/* END Header */

/* Main */

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

main .subtitle {
    text-align: center;
    font: 600 42px/52px Montserrat;
    letter-spacing: 0;
    color: #FFFFFFD1;
}

form.registration {
    width: 100%;
}

form select, input:not([type=checkbox]) {
    height: 93px;
    border-radius: 8px;
    border: none;
    background: #FFFFFFE6 0 0 no-repeat padding-box;
    outline: none;
    box-shadow: none;
}

form div.landing-custom-select {
    font-weight: 400;
    height: 93px;
    overflow: hidden;
    background: white;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    margin-right: 50px;
}

form div.landing-custom-select select {
    flex: 1 1 auto;
    display: block;
    margin-right: 50px;
    font-size: 32px;
    color: #3A3A3ABF;
    border: none;
    padding: 31px 0 26px 42px;
    height: 93px;
    width: 274px;
    -webkit-appearance: none;
    appearance: none;
    background: url('../img/arrow_down.png') 96% / 15% no-repeat padding-box;
}

form div.landing-custom-select select.adjusted-padding {
    padding-top: 26px;
    background-position: 85% center;
    background-size: 10%;
}

form div.landing-custom-select.full-width {
    width: 100%;
}

form div.landing-custom-select.full-width select {
    background-size: 2%;
    margin-right: 0;
    width: 100%;
    line-height: 35px;
}

form input[type=checkbox] {
    cursor: pointer;
    margin-left: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: auto;
    height: auto;
}

form input[type=checkbox]:before {
    content: '';
    border: 1px solid white;
    border-radius: 3px;
    display: inline-block;
    background: transparent;
    width: 33px;
    height: 33px;
}

form input[type=checkbox]:checked:before {
    background: white url("../img/checkmark.svg") 33px 45% / 120%;
    color: white;
}

form div.form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 44px;
}

form div.form-group .input-control {
    flex: 1 1 auto;
    display: block;
    margin-right: 50px;
    font-size: 32px;
    padding: 31px 0 28px 48px;
}

form div.form-group .input-control.has-error,
form div.landing-custom-select select.has-error {
    background-color: #F6B9B9;
    border: 4px solid #FF0000;
    border-radius: 9px;
    padding: 11px 0 18px 10px;
}

form div.form-group input.input-control.has-error::-webkit-input-placeholder {
    color: #303030CC;
}

form div.form-group.without-margin {
    margin-bottom: 0;
}

form div.form-group :last-child {
    margin-right: 0;
}

form small.information {
    font: 600 22px/30px Montserrat;
    font-size: 22px;
    color: #dededecc;
    margin-bottom: 35px;
    padding: 0.5em;
}

form div.checkbox-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 33px;
    margin-bottom: 22px;
}

form label.checkbox-text {
    font: 500 28px/30px Montserrat;
    font-size: 28px;
    color: #e8e8e8e6;
    margin-left: 35px;
}

form div.tooltip {
    display: flex;
    align-items: center;
    background-color: #F6B9B9;
}

form div.tooltip:before {
    content: "";
    position: relative;
    left: 25px;
    bottom: 38px;
    width: 0;
    height: 0;
    border-top: 26px solid transparent;
    border-left: 16px solid #F6B9B9;
}

form div.tooltip img.tooltip-info-icon {
    padding: 15px 0 17px 20px;
    color: #fff;
}

form span.tooltiptext {
    color: #fff;
    margin-left: 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
}


form.registration input {
    font-weight: 400;
    color: #3a3a3a;
}

form input::-webkit-input-placeholder {
    text-align: left;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0;
    color: #77777766;
    opacity: 1;
}

form button.btn-submit {
    font-weight: 400;
    background: #DD490A;
    color: #fff;
    text-align: center;
    padding: 32px 0;
    border-radius: 12px;
    border: 3px solid #fff;
    font-size: 38px;
}

form button.btn-block {
    width: 100%;
}

form small.required-tooltip {
    font-weight: 300;
    font-style: italic;
    color: #e8e8e8e6;
    margin-bottom: 17px;
}

.fit-container {
    display: block;
    flex-basis: 100%;
}

/* END Main */


/* Footer */
footer {
    margin: 230px 0 22px 0;
}

footer p {
    font: Italic 24px/30px Montserrat;
    height: 30px;
    text-align: center;
    color: #E8E8E8E6;
}

/* END Footer*/

/* Feedback part */
.feedback {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 890px;
    height: 553px;
    background: white;
    color: #DD490ABF;
    box-shadow: 0 30px 40px #FFFFFF6B;
    border-radius: 12px;
    margin: 310px auto;
}

.clear-container {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-end;
}

.clear {
    cursor: pointer;
    margin: 12px;
}

.feedback-headline {
    font: 600 38px/52px Montserrat;
    text-align: center;
    margin: 54px 147px 25px;
}

span.greetings {
    display: block;
}

.feedback-text {
    font: 200 32px/52px Montserrat;
    margin: auto 125px;
    width: 641px;
    text-align: center;
}

button.accept {
    width: 257px;
    height: 84px;
    margin-top: 66px;
    margin-bottom: 44px;
    font: 400 50px/52px Rubik;
    background: #DD490AE6;
    border-radius: 42px;
    color: #fff;
}


/* Media queries */

/*Breakpoint from 320px */
@media screen and (min-width: 320px) and (max-width: 1200px) {
    form div.form-group {
        margin-bottom: 0;
    }

    form small.required-tooltip {
        text-align: center;
        margin: 20px 0;
    }

    footer {
        margin: 10px 0;
    }

    footer p {
        height: auto;
        padding: 15px;
        font-size: 16px;
    }

    /* Feedback */
    .feedback {
        width: 80%;
        height: 300px;
        margin: 75px auto;
        box-shadow: 0 10px 20px #ffffff6b;
    }

    .clear {
        margin: 4px;
    }

    p.feedback-headline {
        font: 600 16px/20px Montserrat;
        margin: 12px 0 0;
    }

    p.feedback-text {
        width: 80%;
        font: 200 16px/20px Montserrat;
        margin: 0 auto;
    }

    button.accept {
        width: 50%;
        height: 17%;
        margin: 0;
        font-size: inherit;
    }
}

@media screen and (min-width: 433px) and (max-width: 767px) {
    form div.landing-custom-select select {
        width: 80%;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    form input::-webkit-input-placeholder {
        font-size: 18px;
    }

    .header-logo {
        width: initial;
        margin-top: 53px;
        opacity: 0.9;
    }

    main .subtitle {
        font-size: 29px;
    }

    form div.form-group {
        margin-bottom: 0;
    }

    .input-control {
        width: 20px;
    }

    form div.form-group .input-control,
    form div.landing-custom-select {
        flex: 0;
        flex-basis: 90%;
        margin-right: 0;
        margin-bottom: 12px;
    }

    form div.landing-custom-select select,
    form div.landing-custom-select select.adjusted-padding {
        width: 100%;
        background-position: 95% center;
        background-size: 8%;
        font-size: initial;
    }

    form div.landing-custom-select.full-width select {
        background-position: 95% 58%;
        background-size: 5%;
        margin-right: 0;
        width: 100%;
    }

    form small.information {
        text-align: center;
        margin-bottom: 15px;
        font-size: 16px;
    }

    form label.checkbox-text {
        font-size: 9px;
        margin-left: 25px;
    }

    form div.checkbox-group {
        margin: 10px auto;
        width: 90%;
    }

    form div.tooltip {
        width: 90%;
        margin: 25px auto;
    }

    form input[type=checkbox] {
        margin-left: 25px;
    }

    form small.required-tooltip {
        text-align: center;
        margin: 20px 0;
    }

    form button.btn-block {
        width: 85%;
        display: block;
        margin: auto;
        font-size: 22px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    form div.form-group .input-control,
    form div.landing-custom-select {
        flex: 0;
        flex-basis: 80%;
        margin-right: 0;
        margin-bottom: 12px;
    }

    form div.landing-custom-select select,
    form div.landing-custom-select select.adjusted-padding {
        width: 100%;
        background-position: 30% 55%;
        background-size: 4%;
    }

    form small.information {
        text-align: center;
        margin: auto auto 15px auto;
        width: 80%;
    }

    form label.checkbox-text {
        font-size: 15px;
        width: 80%;
        margin-left: 0;
        text-align: center;
    }

    form div.checkbox-group {
        margin: 10px auto;
        width: 85%;
    }

    form div.tooltip {
        width: 80%;
        margin: 25px auto;
    }

    form input[type=checkbox] {
        margin-left: 0;
    }

    form button.btn-block {
        width: 85%;
        display: block;
        margin: auto;
        font-size: 22px;
    }

    form div.landing-custom-select.full-width select {
        background-position: 95% 58%;
        background-size: 5%;
        margin-right: 0;
        width: 100%;
    }

    /* Feedback */
    .feedback {
        width: 60%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    main .subtitle {
        width: 85%;
    }

    .feedback {
        width: 40%;
    }

    p.feedback-headline {
        margin: 0;
    }
}
