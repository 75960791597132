@import '~antd/dist/antd.css';
/*============= fonts ==============*/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700');

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

html, body {
  background: #f0f2f5;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.App {
  font-family: Roboto;
}

.App-content {
  background: #fff;
  padding: 24px;
  margin: 0;
  margin-top: 20px;
  min-height: 280px;
}

#root, .ant-layout {
  height: 100%;
  display: block;
}

/* .App > .ant-layout {
  display: block;
} */

.App > .ant-layout {
  margin-left: auto;
  margin-right: auto;
  max-width: 992px;
  padding: calc(64px + 1rem) 24px 24px 24px
}

.layout-xl {
  max-width: 1200px !important;
}

.ant-menu {
  background-color: #2c2e3e;
  font-family: Roboto;
}

.ant-menu-sub.ant-menu-inline {
  background-color: #2c2e3e;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #2c2e3e;
}

.Content-header {
  width: 100%;
  padding: 0 1rem;
}

.Content-header h1 {
  margin-bottom: 0;
  display: inline-block;
}

.Content-header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.ant-input:placeholder-shown {
  height: 30px;
}

form select, input:not([type=checkbox]).ant-input {
  border: 1px solid #d9d9d9;
  height: 45px;
}

.ant-table-wrapper {
  padding: 10px 10px 0 10px;
  margin-top: 10px;
}

.ant-table {
  font-family: Roboto;
  font-size: 16px;
}

.ant-table-thead > tr > th {
  background: #5d6b75;
  /* background: #4087c2; */
  color: white;
  font-weight: 600;
}

.ant-table-tbody > tr > td {
  color: black;
  background-color: white;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background: #5d6b75c7;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #5d6b75c7;
}

body, .ant-form-item, .ant-input, .ant-radio-wrapper, .ant-input-number, .ant-select {
  font-size: 16px;
}

.ant-checkbox + span {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-input, .ant-input-number, .ant-btn {
  height: 40px;
}

.ant-input-number-input {
  height: 30px !important;
}

.ant-select-selection--multiple {
  min-height: 38px;
}

.ant-btn {
  font-size: 18px;
}

.ant-btn-sm {
  font-size: 14px;
  height: 24px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-collapse-borderless {
  background: #f0f2f5;
}

.ant-collapse {
  font-size: inherit;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0;
}

.ant-form-item-label label:after {
  content: '';
}

.tb {
  display: table;
  height: 100%;
  width: 100%;
}

.tbc {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.filedrop .filedrop-body .tb .tbc {
  background-color: #5a6b75;
}

.mb-4 {
  margin-bottom: 1.2em;
}
